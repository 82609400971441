import React from "react";
import { Link } from "gatsby";

import SEO from "../components/seo";
import Layout from "../components/layout";
import "../styles/layout.scss";

const ThankYou = () => (
  <Layout>
    <SEO title="Takk" />
    <div style={{ maxWidth: "500px", margin: "100px auto", padding: "0 4%" }}>
      <h1>Takk for bestillingen</h1>
      <p>Coachen vil komme tilbake til deg innen kort tid.</p>
      <Link to="/">Tilbake til hjemmesiden</Link>
    </div>
  </Layout>
);

export default ThankYou;
